import React from "react";
import {Button, Card, CardContent, CardHeader, Container, Form, Message, Grid, Image} from "semantic-ui-react";
import {UsernameEmailField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";
import cookie from "react-cookies";
import icon from "../../../images/notes.png";
import orderinfo from "../../../images/main/orderinfo.png";
import bg from "../../../images/main/bg.png";
import NavigationBar from "../navigation-bar";

function BillDetailView(props) {
    const {
        ready,
        onContinue,
        message,
        msgs
    } = props;
    const mode = detectOrient();
    let backcolor = "#8c0c07";
    if (mode === "LANDSCAPE"){
        backcolor = "transparent";
    }
    return (
        <div className="whiteDiv" textAlign='center' style={{
            width:"100%",
            minHeight: "100vh",
            textAlign:"center",
            margin:"0 auto"
        }} >

        <NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={0} onBack={onContinue}/>

            <div style={{maxWidth: 450,  margin:"0 auto"}}>
                <div >
                
                <div
    style={{     
        width:"100%",
        // backgroundColor: `${backcolor}`,
        backgroundImage: `url(${bg})` , backgroundSize:'100% 100%',
        minHeight:"100vh",
        marginTop:"80px"
}}
    >            
        <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
        <div textAlign='center' verticalAlign='middle'>
<div style={{maxWidth: 450}}>
    <Container fluid style={{
        maxWidth: "450",
        paddingLeft:`${(mode==="PORTRAIT")?"":"1rem"}`,
        paddingRight:`${(mode==="PORTRAIT")?"":"1rem"}`,
        }}>            
        <div style={{
                borderRadius:'5px 5px 0px 0px',
                // marginLeft:"2vh"
            }}>
                <CardContent style={{
                    // backgroundColor: "#8c0702",
                    height: 40,
                    padding: "5px",
                    borderRadius:'5px 5px 0px 0px',
                    color:"#fff",
                    paddingLeft: "10px",
                    fontSize: "16px"
                }}>
                    <div style={{float:"left", paddingTop:"2px"}}>特權卡發貨記錄</div>
                    <Button  style={{
                    float:"right", paddingTop: 5, 
                    paddingBottom: 5, color:'#fff',
                    width:"95px",height:"35px",
                    backgroundImage: `url(${orderinfo})` , backgroundSize:'100% 100%',
                    backgroundColor:"transparent",
                }} 
                    as={Link}
                            to={'/last-report'}>
                        <div style={{float:"right",fontSize: "13px"}}>&nbsp;</div></Button>
                </CardContent>
                </div>
                {((!ready) || (!msgs)) && (<CardContent style={{
                    backgroundColor: "#F3F3F3",
                    height: 35,
                    padding: "5px",
                    color:"#333",
                    paddingLeft: "10px",
                    fontSize: "16px"
                }}>
                    <div style={{float:"left", paddingTop:"2px"}}>{message}</div>                        
                </CardContent>)}

                {(ready) && (msgs) && 
                    msgs.map((msg, idx) => {
                        let topname = "";
                        let createdAt =  dateFormat(msg.createdAt, "yyyy-MM-dd hh:mm")+" (GMT+8)";;
                        if (msg.msg.count === 1){
                            topname = "購買";
                            createdAt = dateFormat(msg.msg.createdAt, "yyyy-MM-dd hh:mm")+" (GMT+8)";
                        }
                        const transactionId = msg.msg.transactionId;
                        const itemName = msg.msg.caption;
                        let gold = msg.msg.gold;
                        // console.log("1111");
                        // console.log(gold);
                        // if (itemName.indexOf("銀聯活動免費送三日卡") !== -1){
                        //     gold = 20;
                        // }
                        const message1 = msg.msg.count + "/" + msg.msg.daycount + "天 (每天發放 <span style='color:green;font-weight:bold;'>" + gold + " OP幣</span>)";
                        const amount = msg.msg.nowamount + " OP幣";
                        
                        return (<>
                        {(idx !== 0) && (
                            <div style={{height:"15px"}}></div>
                        )}
                        <div style={{
                    backgroundColor: "#F3F3F3",
                    color:"#333",
                    fontSize: "16px",
                    overflow:"hidden",
                    border: "1px solid #8c0702"
                }}>
                <div style={{overflow:"hidden", borderBottom: "1px solid #8c0702"}}>
                    <div style={{width:"35%", height:"100%", float:"left",textAlign:"right", padding:"4px", color:"#333", fontSize: '12px'}}>{topname}日期：</div>
                    <div style={{width:"65%", height:"100%",float:"right",textAlign:"left", padding:"4px", fontSize: '12px', borderLeft: "1px solid #8c0702"}}
                    >{createdAt}</div>
                </div>
                <div style={{overflow:"hidden", borderBottom: "1px solid #8c0702"}}>
                    <div style={{width:"35%", height:"100%",float:"left",textAlign:"right", padding:"4px", color:"#333", fontSize: '12px'}}>訂單號：</div>
                    <div style={{width:"65%", height:"100%",float:"right",textAlign:"left", padding:"4px", fontSize: '12px', borderLeft: "1px solid #8c0702"}}
                    >{transactionId}</div>
                </div>
                <div style={{overflow:"hidden", borderBottom: "1px solid #8c0702"}}>
                    <div style={{width:"35%", height:"100%",float:"left",textAlign:"right", padding:"4px", color:"#333", fontSize: '12px'}}>礼包名称：</div>
                    <div style={{width:"65%", height:"100%",float:"right",textAlign:"left", padding:"4px", fontSize: '12px', borderLeft: "1px solid #8c0702"}}
                    >{itemName}</div>
                </div>
                <div style={{overflow:"hidden", borderBottom: "1px solid #8c0702"}}>
                    <div style={{width:"35%", height:"100%",float:"left",textAlign:"right", padding:"4px", color:"#333", fontSize: '12px'}}>發幣內容：</div>
                    <div style={{width:"65%", height:"100%",float:"right",textAlign:"left", padding:"4px", fontSize: '12px', borderLeft: "1px solid #8c0702"}}
                    dangerouslySetInnerHTML={{__html: message1 }}
                    ></div>
                </div>
                <div style={{overflow:"hidden"}}>
                    <div style={{width:"35%", height:"100%",float:"left",textAlign:"right", padding:"4px", color:"#333", fontSize: '12px'}}>現有：</div>
                    <div style={{width:"65%", height:"100%",float:"right",textAlign:"left", padding:"4px", fontSize: '12px',fontWeight: "bold", borderLeft: "1px solid #8c0702", color:"green"}}
                    >{amount}</div>
                </div>
                <div style={{clear:"both"}}></div>
                </div>
                        </>)
                    })

                }
                
        <div style={{height:"20px"}}></div>
        
    </Container>
    </div>
</div>
</div>
    </div>
                
                </div>
            </div>
        </div> 
    );
}

export default withTrans(BillDetailView);

function dateFormat(time, fmt) { // author: meizz
    console.log();
   const date = new Date(time);
   // console.log(date);
   let o = {
       "M+": date.getMonth() + 1, // 月份
       "d+": date.getDate(), // 日
       "h+": date.getHours(), // 小时
       "m+": date.getMinutes(), // 分
       "s+": date.getSeconds(), // 秒
       "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
       "S": date.getMilliseconds() // 毫秒
   };
   if(/(y+)/.test(fmt))
       fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
   for(let k in o)
       if(new RegExp("(" + k + ")").test(fmt))
           fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
   return fmt;
}

function detectOrient(){
    if(typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    }else{
        return "PORTRAIT";
    }
}