import {connect} from "react-redux";
import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import cookie from "react-cookies";
import Lambda from "../controllers/utilities/aws-lambda";
import KaisakuApi, {getFullPath} from "../controllers/kaisaku-api";
import {navigate} from "gatsby";
import {Button} from "semantic-ui-react";
import View from "../components/forms/bill-detail/bill-detail-view";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            message:"正在加載中..."
        };
        // rejectIfAnyNull(this.props.session, this.props.transactionId);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.location.search);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/sdk-callback/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/sdk-callback");
        }
    }

    async componentDidMount() {
        const data = await KaisakuApi.getCardInfo(this.props.session.accessToken);
        if (data){
            if (data.data.length > 0){
                this.setState({
                    msgs: data.data.sort((a, b) => b.createdAt - a.createdAt)
                });
            }else{
                this.setState({
                    message: "還沒有此類記錄信息！"
                });
            }
        }
        this.setState({
            ready: true
        });
    }

    render() {
        return(
            <View
            ready={this.state.ready}
            message={this.state.message}
            msgs={this.state.msgs}
            onContinue={this.onContinue.bind(this)}
            />
        );
    }


});